html, body {margin: 0; padding: 0; height:100%;}
body{
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.333;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    min-height: 100vh;
    /* background-image: url('/assets/images/bg-top.svg');
    background-repeat: no-repeat;
    background-position-x:right; */
    /* background-position: 0 0;    
    background-size: cover; */
}
.bg-gray{background: #f6ecf5!important;}
.bg-primary {
    background-color: #cedfee !important;
}
#app_root{
    min-height:100%;
    position:relative;
}

.text-primary {
    color: #cedfee !important;
}

.btn-primary {
    background-color: #cedfee !important;
    border-color: #cedfee !important;
    border-radius: 10px;
}

.form-group {
    position: relative;
}

.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #484848;
    padding-left: 0;
}

.form-control+label {
    position: absolute;
    top: 1rem;
    left: 0;
    transition: top .4s;
}

.form-control:focus,
.form-control:active {
    outline: none;
    border-color: #cedfee;
    box-shadow: none;
}

.form-control:focus+label,
.form-control:active+label,
.form-control.touched+label {
    top: -10px;
    font-size: 80%;
    color: #cedfee;
}

.p-relative {
    position: relative !important;
}
.token-modal{max-width: 580px;}
.token-modal .modal-body{padding: 0px;}
.token-modal .modal-body .modal-header{background: #4b83b4; padding: 12px 0;}
.token-modal .modal-body .modal-header h5{display: block; width: 100%; text-align: center; color: #fff; font-weight: 400; margin: 0; font-family: 'Gothic A1'; letter-spacing: 0.8px;}
.token-modal .modal-body .main-box{width: 94%; margin: 20px auto;}
.token-modal .modal-body .main-box .form-control{color: #484848;}
.token-modal .modal-body .main-box .form-control::placeholder{color: #484848; font-weight: 400; font-family: 'Gothic A1';}
.token-modal .modal-body .main-box .btn-primary{background-color: #4b83b4!important; border-color: #234c82!important; font-family: 'Gothic A1';}
.token-modal .modal-body .main-box .btn-primary:hover{background-color: #234c82!important; }
.token-modal .modal-body .main-box .or, .orwrap {display: block; margin:35px auto 20px; border-radius: 50%; width: 44px; height:44px; line-height: 46px; text-align:center;
    background-color: #3b475b; color: #fff; font-weight: 500; font-size: 18px; }
.orwrap{margin: 5px 50% 10px auto}
.token-modal .modal-body .main-box h6{color: #ed1c24; font-size: 16px; margin-top: 10px;}
.token-modal .modal-body .token_display_wrap{background: #fff; border:solid 1px #e5e5e5; border-radius: 16px; width: 90%; padding:40px 30px; text-align: center; color: #4b4b4d;
line-height: 1; font-size: 52px; font-weight: bold; margin: 35px auto 20px;}
.token_type_apointment{border-top: solid 1px rgba(65, 68, 124, 0.20); padding: 20px 0 0; margin-top: 20px;
    color: #484848; font-size: 22px; font-weight: 400;}
.token_type_apointment strong{color: #f37b89; display: block; font-weight: 500; padding-top: 20px; font-size: 26px;}
.patientTokenList{width: 94%!important;}
.patientTokenList h6{text-align: center; color: #484848!important; font-size: 24px!important; font-weight: 500; margin: 0px;}
.patientTokenList table{text-align: center; width: 100%;}
.patientTokenList table tr{background: #f6ecf5; display: flex; margin: 10px 0; border-radius: 10px; padding: 6px 16px; align-items: center;
    justify-content: center;}
.patientTokenList table tr td:first-child{width: calc(100% - 140px); text-align: left; color: #374380; font-weight: 600;}
.patientTokenList table tr td:last-child{width: 140px;}
.cursor-pointer{cursor: pointer;}
.get_token_btn .btn-primary{background-color: #5ac2aa!important; border-color: #5ac2aa!important;}
.get_token_btn .btn-primary:hover{background-color: #43aa92!important; border-color: #43aa92!important;}
.modalClose{position: absolute; top: 12px; right: 12px; width: 24px; height: 24px; border: none; padding: 0px; background: transparent; cursor: pointer;}
.modalClose::after, .modalClose::before{content: ''; width: 100%; background: #fff; height: 0.1rem; display: block; position: relative;}
.modalClose::before{transform: rotate(45deg); top: 1px;}
.modalClose::after{transform: rotate(135deg);}
.modalClose:focus{outline: none;}
.modal .modal-content {
    border: none;
    border-radius: .5rem;
    overflow: hidden;
}

.modal .main-box {
    position: relative;
    width: 100%;
    background: #fff;
}

.modal .main-box .token {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
}

.home-banner{
    background: #fff; 
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        /* background-image: url('/assets/images/banner-bg.svg');
        background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}
.home-banner .banner-header {
text-align: center;
margin-bottom: 130px;
}
.home-banner .banner-header img {
max-width: 220px;
}

.home-banner .banner-footer{
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    text-align: center;
}
.home-banner .banner-footer p{
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 1.5;
}
.home-banner .banner-footer img{
    width: 80px;
}
#header {
    text-align: center;
    margin: 1rem auto;
}
@media only screen and (max-width:767px) {
    .home-banner .banner-header {
        margin-bottom: 70px;
    }
    .home-banner .banner-header  img {
        width: 300px;
        }
        
}
#header .branch-name {
    font-weight: normal;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 1rem;
}

.kiosk-header .kiosk-navbar {
    background: #fff;
        justify-content: center;
}

.kiosk-header .kiosk-navbar .navbar-brand {
    margin-right: 0;
    padding:1rem 0 0rem;
    position: relative;
    z-index: 2;
}

.kiosk-header .kiosk-navbar .navbar-brand>img {
    max-width: 180px;
}

.kiosk-header .kiosk-branch {
    border-left: 2px solid;
    padding-left: 1.5rem;
    color: #000;
    font-size: 1.35rem;
    line-height: 1.4;
}
.kiosk-header .collect_token{width: 100%; text-align: center; color: #464749; position: relative;
    font-size: 32px; font-weight: 600; font-family: 'Gothic A1'; margin-top: 30px; z-index: 1;}

.modal-lg {
    max-width: 700px;
}

.new_token_card{  box-shadow: 4px 5px 10px 0 rgba(0, 0, 0, 0.07);}
.new_token_card .card-body{padding:0px;  display: flex; align-items: center; justify-content: start; min-height: 65px;}
.new_token_card .card-body figure{width: 60px; height: 60px; margin: 0 15px 0 0; text-align: left;
    border-radius: 50%; }
.new_token_card .card-body figure img {width: 100%; height: auto; position: relative; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.new_token_card .card-body .get_token h5 span{ padding: 10px 0; color: #44aa8a;;}
.new_token_card .card-body .get_token h6{font-size: 16px; font-weight: 600; margin-top: 5px;}
.timestamp{color: #ed1c24; display: block; text-align: center; font-size: 20px; font-weight: 500;; padding: 0 0 30px;}
.timestamp span{
    color: #ed1c24;
}
.pairing-code-box {
    text-align: center;
    position: relative;
    padding: 0px 15px;
    /* top: 50%;
    transform: translateY(50%); */
}

.pairing-code {
    color: #606060;
    font-size: 50px;
}

.pairing-code-helptext {
    color: #606060;
    text-align: center;
    font-size: 26px;
    line-height: 1.4;
    margin: 0px 15px;
}
@media only screen and (max-width:767px) {
 
    .pairing-code {
   
        font-size: 36px;
    }
    .pairing-code-helptext {
        font-size: 16px;
    }
}
.service-card {
    background:#4b83b4;
    cursor: pointer;
    position: relative;
    transition: background-color .4s;
    border-radius: 10px;
    border: solid 1px #cccbcb;
    padding: 12px 16px;
}
.service-card:hover, .service-card:focus {
    background:#234c82;
}

.service-card.clicked {
    background-color: #cedfee;
    color: #fff !important;
}

.service-card.clicked .text-primary {
    color: #FFF !important;
}

.card-icon-container {
    height: 60px;
}

.card-icon-container .card-icon {
    width: auto;
    max-height: 100%;
}
.get_token_items{position: relative; top: 50%; transform: translateY(30%);}
.get_token figure{width: 80px; height: 80px; background: #fff; margin:0 15px 0 0; border-radius: 50%; float: left;}
.get_token figure img{width: 62%; height: auto; position: relative; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.get_token h5{padding-top: 0px; color: #fff; font-size: 24px;   line-height: 1.46; font-weight: 600; font-family: 'Gothic A1'; margin-bottom: 0px;}
.get_token h6{padding-top: 5px; color: #fff; font-size: 16px; font-weight: 500; font-family: 'Gothic A1';}
.appoinment_card{padding: 12px 12px 0px; border:none;}
.appoinment_card:last-child{padding-bottom: 12px;}
.appoinment_card .card-body{background: #f8f8f8; padding: 10px; border-radius: 10px; margin-bottom: 12px;}
.appoinment_card .card-body span{font-size: 16px; font-weight: 500; color: #374380; font-family: 'Gothic A1'}
.appoinment_card .card-body strong{font-size: 16px; font-weight: 600; color: #374380; font-family: 'Gothic A1'}
.appoinment_card .card-body strong.time{color: #f37b89;}
.appoinment_card .card-body .chevron::before{color: #374380; height: 10px; width: 10px;}
.info-icon {
    position: absolute;
    top: .5rem;
    right: .5rem;
}

.info-icon>img {
    width: 30px;
}
.not_exists{text-align: center;}
.not_exists p{color: #484848; font-size: 22px; font-weight: 500; line-height: 1.5;}
.not_exists p a{color: #484848;}
.not_exists p a:hover{color: #e3556a;}
.not_exists .red{color: #e3556a;}
.not_exists .qrcode{border: solid 1px #ddd; width: 100px; height: 100px;}
.citizen_info{font-size: 22px; padding-left: 22px; color: #484848; font-weight: 600;}
.chevron {
    position: relative;
}

.chevron::before {
    content: '';
    border-style: solid;
    border-width: 2px 2px 0 0;
    display: inline-block;
    height: 13px;
    width: 13px;
    color: #00a850;
}

.chevron.right::before {
    left: 0;
    transform: rotate(45deg);
}

.lds-dual-ring {
    width: 34px;
    height: 34px;
    margin: auto;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
#footer{ position: relative; bottom: 0px; width: 100%;}
#footer figure{max-width: 90px; height: auto; margin: 0 auto 10px;}
#footer figure img{width: 100%; height: auto;}
#footer p{font-size: 16px; color: #666; margin-bottom: 6px; font-weight: 400;}
.modal-lg{max-width: 840px;}
.container-minheight{
    min-height: calc(100vh - 310px);
    max-width: 94%;
}
.btn-primary.focus, .btn-primary:focus{
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%);
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 1025px) {
    html,
    body {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767.98px) {
    body{
        background-size: 30%;
    }
    .kiosk-header .kiosk-navbar .navbar-brand{
        margin: auto;
    }
    .kiosk-header .collect_token{
        position: relative;
        margin-bottom: 20px;
    }
    .get_token_items{
        -webkit-transform: translateY(0%);
    transform: translateY(0%);
    }
    #footer {
        position: relative;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .kiosk-header .kiosk-navbar .navbar-brand > img {
        max-width: 100%;
    }
}
